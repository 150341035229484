.presentation-page-header {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 30px;
  &__logo {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 3px;
    border: 1px dotted #d8d8d8;
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    .presentation-card__edit-btn {
      background-color: rgba(#fff, 0.3);
    }
    &:hover {
      .presentation-card__edit-btn,
      .btn-custom__delete {
        background-color: rgba(#fff, 0.9);
      }
    }
    & > button.btn-transparent,
    & > .lock-value {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    button.btn-transparent {
      cursor: pointer;
    }
    .ant-upload-disabled {
      background-color: $white;
    }

    .presentation-card__edit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 5px;
      right: 5px;
      z-index: 3;
      padding: 10px;
      transition: 0.3s;
      border-radius: 50px;
    }
    .btn-custom__delete {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      padding: 10px;
      transition: 0.3s;
      border-radius: 50px;
    }
  }

  &__monitor-suppliers {
    display: flex;
    align-items: center;
    margin-left: 15px;
    @include for-size(ml) {
      margin-left: 0;
    }
    padding-right: 15px;
    margin-right: 0px;
    border-right: 1px dotted $light-gray;

    // svg {
    //   width: 20px;
    //   height: 20px;
    // }
  }

  &__favorite {
    display: flex;
    align-items: center;
    margin-left: 15px;
    @include for-size(ml) {
      margin-left: 0;
    }
    padding-right: 15px;
    margin-right: 20px;
    border-right: 1px dotted $light-gray;

    svg {
      width: 20px;
      height: 20px;
    }

    & > button {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }

      .add-to-favorites {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 10px;
        color: $green;
        font-weight: bold;
        text-transform: uppercase;
      }

      img {
        width: 40px;
      }
    }
  }

  &__left-side {
    min-width: 234px;
    &.presentation-header-edit {
      padding: 25px 20px;
      border-radius: 3px;
      border: 1px dotted #a9a9a9;
    }
  }

  &__right-side {
    padding-right: 40px;
    flex: 1;

    &.presentation-header-edit {
      .presentation-header-edit_inner-wrapper {
        padding: 25px 30px;
        border-radius: 3px;
        border: 1px dotted #a9a9a9;
      }
    }
  }

  &__titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    & > .btn-custom_main.btn-transparent > svg {
      margin-right: 6px;
    }
  }

  &__name {
    display: initial;
    font-size: 24px;
    padding-right: 20px;
    margin-bottom: 0;
    font-weight: bold;
    color: $black;
    width: auto;
  }

  .description-placeholder {
    border: 1px dotted $light-gray;
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: #8c8c4a;
      background: #ffff84;
    }
  }

  &__about-org {
    display: flex;
    align-items: flex-start;
    width: 100%;
    @include for-size(ml) {
      flex-direction: column;
    }
    &.presentation-header-edit {
      width: auto;
    }
    .meta {
      margin-top: 9px;
      flex-wrap: nowrap;
      flex: 1;
      @include for-size(ml) {
        margin-left: 0;
        margin-top: 20px;
      }
      .icons {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px dotted #d8d8d8;
      }
    }
    .edit-tip {
      margin-left: 10px;
      white-space: nowrap;

      .emoji {
        margin-right: 10px;
        font-size: 24px;
      }
    }
  }

  &__not-edit {
    box-shadow: none;
    background: transparent;
    padding-left: 0;
  }

  &__input {
    height: 44px;
    color: $black;
    width: 135px;
  }

  &__input-name {
    font-weight: bold;
    font-size: 24px;
    height: auto;
    color: $black;
    width: auto;
    padding: 10px 15px;
    line-height: 1;
  }

  &__input-description {
    display: block;
    margin-bottom: 20px;
    padding: 10px 15px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 60px;
    max-height: 200px;
    background: $white;
    border: none;
    font-size: 14px;
    color: $discrete-text;
    line-height: 17px;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    outline: none;
  }
  &__input-description.request {
    min-height: 150px;
    max-height: 500px;
  }

  &__input-site {
    height: 44px;
    padding: 10px 15px;
  }

  &__info-org {
    margin-top: 10px;
    line-height: 1;
    &:last-child {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px dotted $light-gray;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    button {
      cursor: pointer;
    }
  }

  .report-profile {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    margin-left: 20px;

    button {
      height: 20px;
      padding: 0;
      margin: 0;
      > svg {
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
      }
      span {
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        color: $black;
        margin-top: 2px;
      }
    }
  }

  .contextual-menu {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    > svg {
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
    }
  }

  &__select {
    display: flex;
    flex: 1;
    align-items: center;

    span {
      cursor: pointer;
    }
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:before {
        content: url("../../../../../assets/images/svg/ellipsis.svg");
        height: 20px;
      }
    }
    .ant-select-selection-item {
      color: $link;
    }
  }

  &__select-list {
    padding-top: 10px;
    padding-bottom: 10px;
    .ant-dropdown-menu-item {
      padding: 10px;
      display: flex;
      align-items: center;
      img {
        width: 19px;
      }
      span {
        padding-left: 5px;
        color: $link;
        font-size: 14px;
      }
    }
  }

  &__page-link {
    color: $link;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    svg {
      margin-right: 10px;
      fill: $link;
      color: $link;
    }
    &:hover {
      color: #40a9ff;
      svg {
        fill: #40a9ff;
        color: #40a9ff;
      }
    }
    &.__no-link {
      color: $mid-gray;
      svg {
        color: $mid-gray;
        fill: $mid-gray;
      }
    }
  }
}

.presentation-page {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  &__left-side {
    &__cards {
      padding: 0px 20px 20px 20px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      &:not(:nth-child(1)) {
        border-top: 1px dotted $light-gray;
        padding-top: 20px;
      }

      h5 {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
      }

      strong {
        font-weight: 800;
        font-size: 24px;
        display: block;
      }

      span {
        display: block;
      }

      & > span {
        font-size: 10px;
        color: $mid-gray;
      }

      .our-profile-card {
        margin-top: 14px;
      }
    }

    &__credit-update {
      min-width: 194px;
      width: fit-content;
      margin: auto;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      margin-bottom: 20px;
      &__updated {
        font-size: 12px;
        font-weight: 700;
        margin: 20px 0px;
        text-transform: uppercase;
        & > span {
          display: inline;
          font-style: italic;
          font-weight: 400;
          text-transform: none;
        }
      }
      &__info-labels {
        font-size: 10px;
        color: $mid-gray;
        padding: 0px 5px;
        & > span {
          color: $discrete-text;
          margin-left: 5px;
          font-weight: 700;
          display: inline;
        }
      }
      &__link {
        cursor: pointer;
        color: $link;
        font-size: 14px;
        width: 120px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  &__quick-facts {
    margin: 20px auto 0 auto;
    text-align: left;
    width: 50%;
    min-width: 170px;
  }

  &_quick-fact {
    &.__item {
      margin: 0 10px 15px;
      display: flex;
      align-items: center;
    }
    &.__title {
      font-size: 12px;
      line-height: 130%;
    }
    &.__icon {
      display: flex;
      align-items: center;
      width: 22px;
      margin-right: 15px;
    }
  }

  &__right-side {
    display: flex;
    flex: 1;
    overflow: hidden;

    &__tabs-wrapper {
      overflow: hidden;
    }

    &__tabs {
      padding-left: 10px;
      margin-bottom: 74px;
      &-wrapper {
        .tabs-content {
          .overview-section {
            &_wrapper {
              padding-left: 10px;
              .editBlock_body.__flex {
                margin-right: -20px;
              }
            }
            &_presentation-row {
              display: flex;
              flex-flow: row nowrap;
              & > .presentation-card {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  &__non-payment {
    color: $red;
  }

  .first-setup_selection__item-wrap {
    margin-bottom: 20px;
  }
}

.avatar-uploader {
  width: 100%;
  height: 100%;
  .ant-upload.ant-upload-select {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
  }
}

.rating-pie {
  &_wrapper {
    margin-top: 20px;
    & > svg {
      width: 160px;
      max-width: 100%;
      margin-bottom: -5px;
    }
  }
  &_pointer {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -20px auto 0;
    border-radius: 0 50% 50% 50%;
    background-color: $light-gray;
    transform: rotate(-135deg);
    i {
      position: absolute;
      font-style: inherit;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: $black;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
    }
    &_0 {
      background-color: transparent;
      transform: none;
      width: 100%;
      text-align: center;
      padding-top: 5.5px;
      i {
        color: $red;
        display: contents;
        font-size: 12px;
        transform: none;
        .bankruptcy-label {
          letter-spacing: -0.5px;
        }
        .bankruptcy-date {
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    &_1 {
      background-color: $black;
      transform: rotate(-27deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(27deg);
      }
    }
    &_2 {
      background-color: $red;
      transform: rotate(9deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-9deg);
      }
    }
    &_3 {
      background-color: $orange;
      transform: rotate(45deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &_4 {
      background-color: $link;
      transform: rotate(81deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-81deg);
      }
    }
    &_5 {
      background-color: $green;
      transform: rotate(117deg);
      i {
        color: $white;
        transform: translate(-50%, -50%) rotate(-117deg);
      }
    }
  }
  &_question {
    margin: -20px 0 0 0;
    text-align: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
