.finance-section {
  &_wrapper {
    padding-left: 10px;
    .beneficial-owners {
      .smaller {
        font-size: 12px;
      }
      .bordered {
        border: 1px solid $light-gray;
        border-radius: 3px;
        padding: 0 5px;
      }
      .list {
        padding: 10px 20px 0 20px;
        border: 1px dotted $light-gray;
        width: fit-content;
        max-width: 100%;
        border-radius: 3px;
      }
      .distrust-level {
        &.good {
          color: $green;
        }
        &.medium {
          color: $orange;
        }
        &.bad {
          color: $red;
        }
      }
    }

    .group-structure {
      padding-bottom: 40px;
      .part-of-group,
      .not-part-of-group {
        font-size: 12px;
        font-style: italic;
      }
      .group {
        padding: 10px 20px;
        border: 1px dotted $light-gray;
        border-radius: 3px;
        width: fit-content;
        .heading {
          margin-bottom: 10px;
        }
      }
      .company-type {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
      }
      .flag {
        display: flex;
        border-radius: 1px;
        overflow: hidden;
      }

      .group-type {
        font-weight: bold;
        font-size: 10px;
        color: $mid-gray;
        line-height: 1;
        text-transform: uppercase;
        margin-left: 10px;
      }
    }
    .no-group-data {
      padding-bottom: 40px;
      h4 {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .annual-report {
      background: $off-white;
      border-radius: 3px;
      padding: 40px;
      width: fit-content;
      &_wrap {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      &_title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        color: $discrete-text;
      }
      &_item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &__title {
          width: 220px;
          font-size: 14px;
          color: $discrete-text;
        }
        &__value {
          font-size: 14px;
          color: $black;
          display: inline-block;
          text-align: right;
          width: 140px;
          margin-right: 10px;
        }
      }
    }
    .non-payment,
    .unpaid-debdts {
      .custom-table_head__date,
      .custom-table_body__date {
        width: 95px;
        color: $discrete-text;
      }
      .custom-table_head__date {
        font-weight: 700;
      }
    }
    .h2 {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  &_sub-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: $discrete-text;
  }
  &_half-block {
    max-width: 560px;
    padding-right: 40px;
    &.right-dotted {
      border-right: 1px dotted #a9a9a9;
    }
  }
}

.custom-table {
  &_head__title,
  &_head__value,
  &_body__title,
  &_body__value {
    width: 200px;
    margin-right: 20px;
    font-size: 14px;
    color: $discrete-text;

    svg > path {
      fill: $discrete-text;
    }

    &.count {
      width: 120px;
    }
    &.value {
      text-align: right;
      width: 100px;
    }
  }
  &_head {
    display: flex;
    &__title,
    &__value {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  &_body {
    &__item {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.__bold {
        div {
          font-weight: 700;
        }
      }
    }
  }
}

.management-table_wrap {
  .custom-table_body__value {
    &:nth-child(2) {
      font-weight: 700;
    }
  }
}

.finance {
  &-table_wrapper {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    .ant {
      &-table {
        &-thead {
          .ant-table-cell {
            font-weight: 700;
          }
        }
        &-row {
          &:hover {
            background-color: transparent;
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
        &-cell {
          padding: 0 0 10px 0;
          border-bottom: 0;
          background-color: transparent;
        }
      }
    }
  }
}
