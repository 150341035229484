.organization-settings {
  &__wrapper {
    padding: 0 40px 40px 40px;
    @include for-size(sm) {
      padding: 0 20px 40px 20px;
    }
    .title {
      text-transform: uppercase;
      font-weight: 700;
    }

    .info {
      margin-bottom: 60px;
    }

    .supplier-qualification {
      .members-to-notify {
        max-height: 103px;
        overflow: hidden;
        padding: 10px 40px 10px 10px;
        margin-left: -10px;
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        overflow-y: scroll;
      }
    }
  }
  &__columns {
    display: flex;
    flex-flow: row nowrap;
    & > div {
      width: 50%;
      @media (max-width: 1199.98px) {
        width: 100%;
      }
    }
    @media (max-width: 1199.98px) {
      flex-flow: column nowrap;
    }
  }
  &__right-column {
    margin-left: 20px;
    @media (max-width: 1199.98px) {
      margin-left: 0px;
    }
  }
  &__department-labels {
    &__department-button {
      height: 30px !important;
      padding-right: 5px;
      margin-right: 20px;
      & > span {
        text-transform: uppercase !important;
        font-size: 10px;
        font-weight: 700;
      }
    }
    &__add-button {
      height: 30px !important;
      padding-left: 5px;
      & > svg {
        transition: all 0.4s ease;
      }
      &:hover {
        transition: all 0.5s ease;
        svg {
          transform: rotate(90deg);
          transition: all 0.4s ease;
        }
      }
    }
  }
  &__dalux-settings {
    .input {
      width: 400px;
    }
    .field-tooltip {
      margin-bottom: 10px;
    }
    &__save-button {
      width: 160px;
      margin: 20px 0px 0px 240px;
      & > span {
        text-align: center;
        width: 100%;
      }
    }
  }
}
