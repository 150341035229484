.request {
  &.__wrapper {
    background: $white;
    margin-bottom: 40px;
    border-radius: 3px;
  }
  &.request-fullscreen-mode {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    min-height: 100%;
    overflow: auto;
    .view-header_right-side,
    .request__tabs,
    .view-header__address,
    .view-header_left-side > div.df > svg,
    .view-header_left-side > div.df > button {
      display: none;
    }
    .view-header_left-side {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    .content-block_header {
      padding-top: 0px;
      padding-bottom: 0px;
      min-height: 106px;
    }
    .content-block_wrapper {
      padding-top: 0px;
      box-shadow: none;
      padding-bottom: 0px;
    }
    .tabs-content {
      .table-title-results {
        margin-top: 0px !important;
      }
      .add-manual-offer {
        display: none;
      }
    }
  }

  &__tabs {
    margin-bottom: 40px;
    display: flex;
    button.tab > a svg {
      margin: 0px 0px -2px -5px;
    }
    &__tab-line {
      font-size: 25px;
      font-weight: 300;
      margin: -9px 30px 0px 0px;
    }
  }

  .content-block {
    &_header {
      margin-bottom: 0px;
      min-height: 156px;
      padding: 40px;

      &.edit-mode {
        padding-bottom: 40px;
      }
    }

    &_wrapper {
      min-height: 50vh;
    }

    &_title {
      line-height: 31px;
    }
  }

  &_head__btn {
    margin-left: auto;
    position: absolute;
    right: 0;
    top: -88px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    &__status-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      border: 1px solid $light-gray;
      border-radius: 3px;
      padding: 10px;
      width: fit-content;
      margin-left: auto;

      .open-rft-switch-locked-icon {
        margin-left: -2px !important;
        margin-right: 15px;
        margin-top: 0px;
        width: 12px;
        height: 12px;
      }
    }

    .video-instruction-link {
      @include for-size(xl) {
        display: none;
      }
    }

    &.in-text-position {
      position: relative;
      top: auto;
    }
    .save-publish-button {
      padding: 10px 20px;
    }
  }

  &_head__text {
    display: inline-flex;
    align-items: center;
    padding: 10px;
    white-space: nowrap;
    font-size: 16px;
    color: $link;
    font-style: italic;
    border: 1px solid $link;
    border-radius: 3px;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: -82px;
    user-select: none;

    &.in-text-position {
      position: relative;
      top: auto;
    }

    svg {
      transform: rotate(10deg);
      animation: swing 4s infinite linear;
    }
  }

  .view-header {
    &._wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &_left-side {
      max-width: 50%;
    }

    &__back-button {
      padding-right: 0;
      padding-left: 0;
      margin-right: 10px;
      & > svg {
        margin: 0px;
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 800;
      margin-top: 20px;
      &.removed-status,
      &.draft-status {
        color: $orange;
      }
      &.on-contract-tab {
        margin-top: 8px;
      }
    }

    &__above-title {
      color: $mid-gray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__address {
      color: $link;
      margin-top: 5px;
    }

    &__status-row,
    &__button-row,
    &__toggles-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__status-row {
      height: 40px;
      font-size: 12px;
      line-height: 40px;
      border: 1px solid $light-gray;
      border-radius: 3px;
      padding: 0px 10px;
      width: fit-content;
      margin-left: auto;
      margin-bottom: 7px;

      .status {
        &.draft {
          color: $orange;
        }

        &.published {
          color: $green;
        }

        &.removed {
          color: $red;
        }

        &.stopped {
          color: $red;
        }
      }

      &__button {
        border: none;
        img,
        svg {
          margin-right: 0px;
        }
      }
    }

    &__toggles-row {
      & > .__closed-offer {
        color: $red;
      }
    }

    &__button-row {
      margin-top: 0px;
      &__button {
        &__remove {
          color: $red;
          border-color: $red;
        }
      }
    }
  }

  .request-edit-block {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    /* need this to position the pending button */
    .main-fields_wrapper {
      display: flex;
      flex-flow: column nowrap;

      &__row-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 20px;

        &.wrappable {
          @include for-size(xl) {
            flex-wrap: wrap;
          }
        }
      }

      &__project {
        border-radius: 3px 0px 0px 3px;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
        height: 60px;
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
      }

      .form-field_wrapper {
        margin-bottom: 0px;
      }

      &__input {
        &.name {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          flex-grow: 2;
          box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px 0px inset;
          .form {
            flex-grow: 2;
          }
          .input {
            width: 100%;
            box-shadow: none;
            margin: 3px;
            height: 54px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
          .categories-button-wrapper {
            padding: 5px;
            margin: 0px 10px;
            border-radius: 20px;
          }
        }
        &.location {
          .input {
            height: 40px;
          }
        }

        &.hide-offers {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin-left: 20px;
          .hide-offers-label {
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            margin-right: 5px;
            @include for-size(xxl) {
              display: none;
            }
          }
          .hide-offers-label-icon {
            display: none;
            height: 21px;
            @include for-size(xxl) {
              display: block;
            }
          }
        }
        .label {
          margin-top: 0px;
        }
      }

      .editBlock_select__item {
        margin-left: auto;
        margin-right: 0px;
        display: flex;
        flex-flow: row nowrap;

        label {
          text-align: right;
          line-height: 40px;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          margin-right: 20px;
          min-width: 100px;
        }

        .ant-select-selector {
          height: 40px;
          padding-left: 10px;
        }
      }

      /* This is a custom tweak to stop the layout from breaking between 990 and 1154 px*/
      @media only screen and (max-width: 1154px) {
        .contact-person {
          label {
            display: none;
          }
        }
        .form-field_wrapper.location-input input {
          width: 160px;
        }
      }

      .close-date {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: fit-content;
        margin-right: 20px;
      }

      .toggles {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @include for-size(xl) {
          order: 2;
          margin-top: 20px;
        }
      }

      .editBlock_date-picker__item {
        max-width: 180px;
        margin-right: 10px;
      }

      .editBlock_date-picker__field {
        height: 40px;

        svg {
          margin-right: 0px;
        }
      }
    }

    form > .form > label {
      margin-top: 10px;
    }
  }

  .request-view-block {
    &_contacts {
      display: flex;
      align-items: center;

      &_title {
        font-size: 16px;
        font-weight: 600;
      }

      &_initials {
        height: 20px;
        width: 20px;
        font-weight: 700;
        font-size: 8px;
        line-height: 20px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        text-align: center;
        user-select: none;
      }
    }
  }

  &-smart-form-block {
    width: 100%;
    &.__top-border {
      margin-top: 40px;
      border-top: 1px dotted $light-gray;
    }
    &__heading {
      padding-top: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      .btn-custom__delete {
        height: 20px;
      }
      &__mandatory-tag {
        position: absolute;
        display: flex;
        align-items: center;
        right: 10px;
        bottom: -60px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
      }
    }
    .smart-form-edit,
    .smart-form-view {
      .question-answer-item {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        gap: 20px;
        font-size: 13px;
        justify-content: space-between;
        & > .__answer-question {
          &.__uppercase.__bold {
            font-size: 12px;
          }
          &.__short {
            & > span {
              font-size: 13px;
              text-transform: none;
            }
          }
        }
      }
      .question-type {
        display: flex;
        flex: none;
        flex-grow: 0;
        .answer-in-unit {
          background: rgba(172, 77, 196, 0.1);
          border-radius: 3px;
          padding: 4px 5px 3px 5px;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: bold;
          color: $purple;
          margin-right: 10px;
        }
      }
    }

    .smart-form-edit {
      .question-answer-item {
        &:not(:last-child) {
          border-style: solid;
          border-width: 0 0 1px 0;
          border-color: #f8f8f8;
        }
      }
    }

    .smart-form-view {
      .question-answer-item {
        .question-and-type {
          .question {
            display: inline;
            line-height: 1.3;
            color: $discrete-text;
            .mandatory {
              margin-bottom: -6px;
            }
          }
        }
        .answer-type {
          display: flex;
          align-items: center;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: $discrete-text;
          .icon {
            margin-right: 5px;
          }
        }
      }
    }

    .smart-form-edit {
      margin-left: -10px;
      margin-right: -10px;
      &.__answers {
        border: 1px dotted $light-gray;
        padding: 10px 10px 20px 10px;
        .question-answer-item {
          padding: 10px;
          justify-content: flex-start;
          & > .form-field_wrapper .ant-input-suffix {
            background-color: $off-white;
            margin-left: 0px;
            padding-left: 9px;
            margin: 4px 2px 3px 0px;
            height: calc(100% - 5px);
            .measurement-label {
              margin-right: 10px;
              padding: 0px 7px;
              border: 1px solid $light-gray;
              border-radius: 15px;
            }
          }
        }
        .__answer-question.__short {
          & > span {
            display: block;
          }
        }
        .mandatory-wrapper {
          margin: 0px 40px 0px 10px;
          width: 17px;
          display: flex;
        }

        .answer-inputs {
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
          min-width: 50%;
        }
      }
      .question-answer-item {
        padding: 10px 10px 0px 10px;
        justify-content: space-between;
        flex-flow: row nowrap;
        align-items: flex-start;
        align-items: center;
        gap: 20px;
        font-size: 13px;

        .form-field_wrapper {
          margin-bottom: 0px;
        }
        .btn-custom__delete {
          height: 40px;
          margin-top: -3px;
        }
        .custom-switch_wrapper {
          height: 32px;
          label {
            font-size: 10px;
          }
        }
        .selection {
          .ant-select-selection-item {
            padding-left: 0px;
          }
        }
      }
    }
    .smart-form-view {
      .question-answer-item {
        &:not(:last-child) {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px dotted $light-gray;
        }
      }
    }
  }

  .recipients-tab {
    &_wrapper {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      .modal-ent__wrapper {
        width: 500px;
        max-width: 80vw;
        .modal-ent__header.__rounded > svg {
          min-width: 20px;
        }
      }

      .empty-data {
        text-align: center;
        font-weight: 700;
      }
      .requests-card_body {
        margin: 0;
        &:not(:last-of-type) {
          border-bottom: 1px dotted $light-gray;
        }
      }

      .instructions-block {
        display: flex;
        flex-flow: wrap;
      }
    }

    &_title {
      font-size: 12px;
      font-weight: 700;
      margin: 40px 0px 10px 0px;
    }

    &_overall-status {
      /* display: flex;*/
      /* hiding this til we have filter matches */
      display: none;
      font-size: 16px;
      border-top: 1px dotted $light-gray;

      &_title {
        font-weight: 600;
      }

      &_count {
        color: $discrete-text;
      }
    }

    &_head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px 10px 0px 0px;

      &__empty {
        width: 45px;
      }

      &__name {
        width: 83%;
      }

      &__column {
        width: 5%;
        margin: auto;
        text-align: center;
      }
    }
    &_email-field,
    &_company-field {
      margin: 20px 0px;
    }
    &_email-field {
      width: 570px;
      & > form > div.field {
        position: relative;
        .form-error {
          position: absolute;
        }
      }
    }
    &_row-icon {
      margin: 20px;
      @include for-size(xl) {
        margin: 20px 10px;
      }
    }

    &_row-results-clear-button {
      text-transform: uppercase;
      margin-left: 20px;
    }

    &_instructions-title {
      font-size: 12px;
    }

    &_instructions {
      display: flex;
      flex-flow: column nowrap;
      justify-content: left;
      align-items: flex-start;

      &_description {
        position: relative;
        padding: 20px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        margin-right: 20px;
        max-width: 500px;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        &.infographic {
          background: none;
          img {
            width: 100%;
            height: auto;
          }
        }

        .result {
          margin-left: 30px;
          flex-direction: column;
          display: flex;
          align-items: flex-start;

          h4 {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: bold;
            color: $discrete-text;
          }

          ul {
            padding: 0;
            font-size: 12px;
          }
        }
      }
    }
    &_table {
      &-title {
        font-size: 24px;
        font-weight: 700;
      }
      &-count {
        font-size: 24px;
        margin-left: 20px;
        &.inactive {
          color: $light-gray;
          text-decoration: line-through;
        }
      }
      &-row {
        &_wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: 10px 0px;
          &:not(:last-of-type) {
            border-bottom: 1px dashed $light-gray;
          }
          svg {
            margin-right: 10px;
          }
          .btn-custom__delete {
            padding: 0px;
            height: 20px;
            margin-left: auto;
            svg {
              margin: 0px;
            }
          }
        }
        &_organization {
          display: flex;
          align-items: center;
          border-radius: 50px;
          &:hover {
            background: rgba(107, 192, 105, 0.2);
            cursor: pointer;
            .recipients-tab_table-row_organization_info {
              color: $black;
            }
            .initials-badge {
              background-color: $off-white;
            }
          }
          &_tag {
            margin-right: 0px;
            height: 32px !important;
            display: flex;
            align-items: center;
            svg {
              margin: 0px 7px 0px 10px !important;
              cursor: default !important;
            }
            span {
              text-align: center;
              width: 16px;
              max-width: 16px;
            }
            &.__hoverable:hover {
              svg {
                width: 20px;
                height: 20px;
                margin: 0px 3px 0px 10px !important;
              }
              span {
                font-size: 14px;
                font-weight: 700;
              }
            }
            &.__clickable {
              cursor: pointer;
            }
          }
          &_info {
            font-size: 12px;
            color: $orange;
            cursor: pointer;
            padding-right: 10px;
          }
        }
      }
    }
    &__members-modal {
      &__content {
        padding: 40px;
        text-align: left;
        & > div {
          font-size: 12px;
        }
        &__list {
          max-height: 213px;
          overflow: scroll;
          font-size: 14px !important;
        }
      }
    }
    &__filtered-recipients-modal {
      max-width: 100% !important;
      width: calc(100% - 160px) !important;
      margin-top: 40px !important;
      height: calc(100% - 90px) !important;
      position: relative;
      .modal-ent__content {
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: unset;
        height: 100%;
        & > .color-row_wrapper {
          flex-grow: 0;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
          & > .color-row:first-child {
            & > .color-row_title {
              margin-top: 20px;
            }
            & > .color-row_strip {
              border-top-left-radius: 3px;
            }
          }
        }
      }
      &__color-row-content {
        width: 100%;
        margin-top: 10px;
      }

      &__done-button {
        margin: -40px 20px 0px auto;
      }
      &__content {
        &_main {
          display: flex;
          height: 100%;
          overflow: hidden;
        }
        &_strip {
          width: 10px;
          background-color: $green;
          border-bottom-left-radius: 3px;
        }
      }

      .organizations {
        padding: 20px 0px;
        border-radius: unset;
        width: 100%;
        .table-title-results {
          padding: 0px 20px;
        }
        &.__results {
          background-color: unset;
          box-shadow: none;
          height: 100%;
          overflow: hidden;
        }
        &__suppliers {
          height: calc(100% - 90px);
          overflow: scroll;
        }

        .organization-card {
          &:hover {
            border: none;
            border-radius: unset;
          }
          &_head,
          &_body {
            padding: 10px 20px;
            &__name {
              &.__wrapper {
                flex-grow: 2;
              }
              &.__select .ant-select-selector {
                padding-left: 0px;
              }
            }
            &__indicator {
              font-size: 10px;
              font-weight: 700;
              &.__sub-status,
              &.__status {
                margin: 0 6px;
              }
              &.__rating {
                min-width: 120px;
                margin-right: 35px;
              }
            }
            &__actions {
              min-width: 150px;
            }
          }
          &_head {
            margin-bottom: 0px;
            border-bottom: 1px solid $search-border;
            &__name {
              &.__select .ant-select-selection-item {
                text-transform: uppercase;
                font-size: 10px;
              }
            }
            &__indicator {
              font-size: 10px;
              font-weight: 700;
            }
          }
          &_body {
            cursor: default;
            border-radius: unset;
            border-bottom: 1px dashed $light-gray;
            transition: none;
            width: unset;
            height: 48px;
            &:hover {
              border-color: transparent;
              border-bottom: 1px dashed $light-gray;
            }
            &:last-child {
              margin-bottom: 65px;
            }
            &__name {
              &.__title {
                .__green-badge {
                  color: $white;
                  padding: 2px 10px;
                  border-radius: 34px;
                  background-color: $green;
                }
              }
              &.__wrapper {
                padding-right: 20px;
              }
            }

            &__indicator {
              &.__rating {
                margin-right: 30px;
              }
            }

            &__action-button {
              height: 26px;
              margin-left: auto;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 10px;
              width: 100px;
              svg {
                height: 16px;
                width: 16px;
              }
              span {
                flex-grow: 2;
              }
            }
          }
        }
      }
      &__bottom-gradient {
        height: 80px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 73.96%,
          #ffffff 100%
        );
        position: absolute;
        bottom: 0px;
        width: calc(100% - 10px);
        margin-left: 10px;
        border-radius: 0 0 3px 3px;
      }
    }
  }

  .proposals-tab {
    &_wrapper {
      .empty-data {
        text-align: center;
        font-weight: 700;
      }
      .modal-manual-offer {
        &__content {
          padding: 20px 40px;
          & > div > div.field {
            & > label.label {
              margin-top: 0px;
              line-height: 22px;
            }
            & > input.input {
              height: 38px !important;
              padding: 4px 11px 4px 20px;
            }
          }
          &__delete-button {
            padding: 0px;
            margin-left: auto;
            & > svg {
              margin-right: 0px;
            }
          }
          .editBlock_field__label {
            padding-left: 0px;
            margin-bottom: 10px;
          }
          .editBlock_date-picker__field {
            height: 38px;
          }
          .load-document-column {
            &__wrapper {
              margin-bottom: 30px;
            }
            &__items {
              max-width: 100%;
              width: 100%;
              border-bottom: 1px solid transparent;
              border-top: 1px solid transparent;
            }
            &__body {
              min-height: 162px;
            }
          }
          &__notes {
            & textarea {
              min-height: 105px;
              padding: 10px 11px 10px 20px;
            }
          }
        }
      }
    }

    &_head,
    &_body {
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      &__row {
        height: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        width: 100%;
        &.__inner {
          font-weight: 400;
          text-transform: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.__adjustments {
          flex-flow: column;
          border-bottom: 1px solid transparent;
          .additional-price-row {
            height: 32px;
            min-height: 32px;
            display: flex;
            align-items: center;
            width: 100%;
            &:not(:last-of-type) {
              border-bottom: 1px solid $light-gray;
            }
            &.__no-divider {
              border-bottom: 1px solid transparent;
            }
          }
        }
        &.__no-divider {
          border-bottom: 1px solid transparent;
        }
        &.__question-row {
          &:last-of-type {
            border-bottom: none;
          }
          span {
            font-size: 12px;
            font-weight: normal;
            color: $black;
            text-transform: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        }
        &.__notes {
          align-items: flex-start;
          padding-top: 6px;
          margin-top: 10px;
          height: 96px;
          border-bottom: none;
        }
      }
    }

    &_head {
      width: 200px;
      padding: 85px 20px 20px 0px;
      text-transform: uppercase;
      font-weight: 700;

      &__row {
        border-bottom: 1px solid $light-gray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.__adjustments {
          padding-top: 0px;
          align-items: flex-start;
        }
        .custom-switch_label {
          text-transform: none;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    &_items {
      display: flex;
      flex-grow: 2;
      box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.09);
      border-radius: 3px;
      overflow-x: scroll;
    }

    &_body {
      padding: 20px;
      border: 1px solid transparent;
      border-radius: 3px;
      transition: 0.3s;
      color: $black;
      min-width: 210px;
      &.__clickable:hover {
        cursor: pointer;
        background-color: rgba(27, 162, 204, 0.05);
      }

      &.__manual-offer {
        height: fit-content;
        width: min-content;
      }

      &__row {
        justify-content: flex-end;
        border-bottom: 1px solid $light-gray;
        &.__name {
          height: 64px;
          border-bottom: none;
          padding: 0px 0px 5px 0px;
          white-space: nowrap;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          & * {
            white-space: initial;
          }
        }

        &.__adjustments {
          align-items: flex-end;
          justify-content: flex-start;
          .additional-price-row {
            justify-content: end;
          }
        }

        &.__unread {
          font-weight: 700;
        }

        &__icon-lock {
          margin: 0px 5px -2px 0px;
        }

        &.__notes {
          & textarea {
            padding: 6px 10px;
            width: 100%;
            min-height: 90px;
            box-shadow: none;
            border: 1px solid $light-gray;
            border-radius: 3px;
            resize: none;
            &:focus,
            &:focus-visible {
              outline: none !important;
            }
          }
        }
      }
    }
  }

  .proposal-card {
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;

      img {
        cursor: pointer;
        color: $link;
      }

      .green-button {
        color: $white;
        background-color: $green;
      }

      .red-button {
        color: $white;
        background-color: $red;
      }

      .evaluating-button,
      .no-thanks-button,
      .create-agreement-button {
        span {
          flex: 1;
        }
      }

      .evaluating-button,
      .no-thanks-button {
        width: 120px;
        height: 32px;
        padding: 5px 10px 5px 5px;
        transition: background-color 0.5s ease;
      }

      .create-agreement-button {
        padding: 5px 10px;

        &:hover {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          height: 42px;
          padding: 10px 15px;
          margin: -5px;
        }
      }
    }

    .seller-side {
      border-top: 1px dotted $light-gray;
      &_contact-header {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        color: $mid-gray;
        margin: 40px 0px 20px 0;
      }

      &_message-header {
        display: flex;
        align-items: center;
        margin: 40px 0px 20px 0;
        font-size: 16px;
        font-weight: 600;

        svg {
          margin-right: 10px;
        }
      }
    }

    .print-button {
      flex: 1 0 auto;
    }

    .main-info {
      display: flex;
      width: auto;

      &_block {
        width: auto;
        padding-top: 40px;
        margin-right: 40px;

        &.detailed {
          width: 100%;
        }

        &_header {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          color: $mid-gray;
          margin-bottom: 5px;
        }

        &_date {
          font-size: 12px;
          color: $discrete-text;
        }

        &_name {
          font-size: 24px;
          font-weight: 800;
          color: $link;
        }

        &_value {
          & > span.seller-value,
          & > span.our-value {
            font-size: 24px;
            font-weight: 800;
          }

          & > span.currency {
            font-size: 14px;
            font-weight: 700;
            margin: 0px 10px 0px 5px;
          }
        }

        &_detailed-values {
          font-size: 14px;
          color: $black;

          div div {
            display: inline-block;
            width: 80px;
          }

          span {
            color: $mid-gray;
          }
        }

        .button-edit {
          margin-left: auto;
        }
      }

      &_contacts {
        display: flex;
        align-items: center;
        padding-top: 40px;

        &_initials {
          height: 40px;
          width: 40px;
          font-weight: 700;
          font-size: 16px;
          color: $white;
          line-height: 36px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border: 2px solid $white;
          border-radius: 50%;
          background-color: $green;
          text-align: center;
          user-select: none;
        }

        &_name {
          font-size: 16px;
          margin-top: -3px;
        }
      }
      & ~ div.main-info {
        width: 40%;
      }
    }
  }

  .modal-ent__wrapper {
    width: 50%;

    @include for-size(l) {
      width: 65%;
    }

    @include for-size(md) {
      width: 80%;
    }

    &.__local {
      width: auto;
    }

    & > .modal-projects > .projects-table_item {
      padding: 0px !important;
      border-bottom: none;

      .project-edit-block {
        width: 100%;
      }
    }
  }

  .addlink-modal {
    &__wrapper {
      margin-bottom: 45px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    }

    width: 430px;
    padding: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &__description {
      padding: 10px 20px 40px 20px;
      font-size: 16px;
    }

    &__form {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__field {
      width: 100%;
      margin: 0px 0px 0px 10px;

      & > span {
        height: 40px;
        border: none;
        box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
      }
      &.__error > span > input {
        color: $red;
      }
    }

    &__submit-button {
      padding: 0px;
      margin-left: 5px;

      svg {
        margin: 0px;
      }
    }
  }

  .message-card {
    &__wrapper {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      border-radius: 3px;
      &.clickable {
        cursor: pointer;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__header,
    &__header-unregistered {
      display: flex;
      align-items: center;
    }
    &__header {
      padding: 20px;
      justify-content: flex-end;
      &-title {
        margin-right: auto;
        font-size: 14px;
        &.bold {
          font-weight: 700;
        }
      }
      .badge {
        margin-left: unset !important;
      }
      &-button {
        padding: 0px;
        svg {
          margin: 0px;
        }
      }
    }
    &__header-unregistered {
      padding: 20px;
      color: $mid-gray;
    }
    &__dropdown__wrapper {
      display: block;
    }

    &__messages-wrapper,
    &__messages-list {
      padding: 0px 20px 20px 20px;
    }

    &__messages-list {
      padding: 3px 0px 0px 0px;
      max-height: 370px;
      overflow: scroll;
    }
  }
}

.recipients-tab_custom-select-dropdown {
  &.ant-select-dropdown {
    max-width: 600px !important;
    width: 600px !important;
    @media (min-width: 320px) and (max-width: 575.98px) {
      width: 320px !important;
      left: 10px !important;
    }

    .top-row {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      & > div {
        padding: 0px;
        margin: 0px 0px 0px 12px;
      }
    }
  }
  &_row > .ant-select-item-option-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $mid-gray;
      flex-shrink: 20000;
      &.row__organization {
        flex-shrink: 1;
        color: $link;
        font-weight: 700;
        margin-right: 10px;
      }
    }
    & > svg {
      margin-left: 10px;
      min-width: 20px;
    }
    .row__statuses {
      display: flex;
      margin-left: auto;
      flex-shrink: 0;
      .organization-card_body__indicator.__sub-status {
        margin: 0;
        margin-left: 10px;
        width: 20px;
        &__rating {
          margin: 0px 4px 0px 10px;
          text-align: center;
          .credit-rating-score__count.__small {
            margin-top: -21px;
          }
        }
      }
    }
  }
}

.contract-tab {
  &__contract-view-block {
    font-size: 16px;

    &__price-value {
      display: flex;
      align-items: center;
      margin-top: 40px;
      font-size: 24px;
      font-weight: 800;
    }

    &__price-denomination {
      margin-left: 10px;
      margin-top: 3px;
      font-size: 14px;
      font-weight: 700;
      color: $mid-gray;
    }

    .textarea {
      &__view {
        padding: 20px;
        width: 100%;
        min-height: 50px;
        box-shadow: none;
        padding: 20px 0px;
        resize: none;
        cursor: default;
      }
    }
  }

  &__contract-edit-block {
    .input.price {
      width: 170px;
      font-size: 24px;
      font-weight: 800;
    }

    .price-desc {
      color: $mid-gray;
      font-weight: 700;
      line-height: 60px;
      margin-left: 10px;
    }
  }

  &_documents-tabs-title {
    display: flex;
    margin-top: 60px;
    margin-bottom: 40px;

    .documents-title {
      margin: 3px 80px 20px 0px;
    }
  }

  &_buttons {
    display: flex;
    padding: 40px 0px;
    border-top: 1px dotted $light-gray;
  }

  &_signatures {
    &__title {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      order: -4;
      svg {
        margin-left: auto;
      }
    }

    &__add-signatory {
      &-title {
        font-size: 16px;
        margin-bottom: 15px;
        order: -3;
      }
      &-select {
        order: -2;
        margin-bottom: 50px;
      }
    }

    &__sides {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      &.__reverse {
        flex-direction: row-reverse;
      }
    }

    &__own-side,
    &__other-side {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
    }

    &__other-side {
      width: 35%;
    }

    &__signatory {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0px;
      margin-bottom: 30px;
      &.__border-bottom {
        border-bottom: 1px dashed $light-gray;
        margin-bottom: 0px;
      }
      &.__first-in-list {
        order: -1;
      }
      .form-field_wrapper {
        margin-bottom: 0px;
      }
    }

    &__status-label {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__status-data {
      font-size: 10px;
      font-weight: 700;
      color: $green;
      white-space: nowrap;
      padding-left: 5px;
    }

    &__waiting-text {
      font-size: 12px;
      color: $orange;
      font-weight: 700;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      svg {
        margin-right: 10px;
      }
    }
  }

  &_done-signatures {
    border-top: 1px dotted $light-gray;
    display: flex;
    flex-flow: column nowrap;
    font-size: 12px;

    &__title {
      color: $mid-gray;
    }

    &__value {
      font-weight: 500;
      padding-bottom: 20px;
    }
  }
}

.filtered-section {
  display: flex;
  flex-direction: column;
  max-width: 45vw;
  .multiselect-custom_list.__inner-wrapper {
    align-items: flex-start;
  }
  .multiselect-custom_btn.__wrapper {
    margin-right: 0;
  }
  .extra-buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
  }
  .recipients-tab_row-our-suppliers-toggle {
    width: fit-content;
    margin-bottom: -12px;
    position: relative;
    left: 250px;
    top: -6px;
  }
}

// elements with positioning outside the DOM
.request-head-edit-hide-offers-icon-tooltip {
  .ant-tooltip-inner {
    max-width: 400px;
    width: max-content;
    @include for-size(xs) {
      max-width: 320px;
    }
  }
}

// Print styles for the proposal card
@media print {
  body,
  .load-document-column__item {
    font-size: 14px !important;
  }
  .request .view-header_left-side {
    max-width: unset;
  }
  .project-label_wrapper,
  .main-info_block_header,
  .main-info_block_date,
  .seller-side_contact-header,
  .request-smart-form-block * {
    font-size: 14px !important;
  }

  .project-label_wrapper {
    height: auto !important;
  }
  .project-label_name,
  .project-label_code {
    padding: 5px 10px;
  }
  .main-content_wrapper {
    background: #fff;
    margin: 40px;
  }
  button,
  .header,
  .footer__links span,
  .footer__links .video-instruction-link,
  .footer__links .language,
  .main-sidebar_wrapper,
  .view-header_right-side,
  .view-header__back-button,
  .request__tabs,
  .proposal-card_header,
  .print-button {
    display: none !important;
    height: 0 !important;
  }
  .footer {
    height: 0 !important;
    .footer-logo {
      position: fixed;
      top: 0;
      height: 10px;
      width: auto;
      right: 0;
    }
  }
  .load-document-column__body {
    min-height: unset !important;
  }
  .request-card {
    background: red;
  }
  .footer,
  .layout-content_wrapper,
  .layout-content_inner-wrap,
  .content-block_wrapper,
  .proposal-card,
  .content-block_header {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    min-height: unset !important;
    box-shadow: none !important;
  }
  .main-info_block {
    width: auto !important;
    padding-right: 40px;
    padding-top: 20px !important;
  }
  .request-smart-form-block {
    &.__top-border {
      border: none !important;
    }
    .mandatory {
      display: none;
    }
    .question-answer-item {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 40px !important;
      .answer-presentation {
        margin-top: 0 !important;
      }
    }
  }
}
