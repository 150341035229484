.contact-member-modal {
  &__content {
    padding: 0px 40px 20px 40px;
  }
  &__contact-info {
    display: flex;
    flex-flow: row nowrap;
    gap: 40px;
    margin-bottom: 40px;
    & > div {
      max-width: 38%;
    }
    &__item {
      line-height: normal;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      &:not(:first-child) {
        margin-top: 8px;
      }

      & > svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex-shrink: 0;
      }
      &.email > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  &__rfps-title {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 40px 0px 20px 0px;
    svg {
      margin-right: 10px;
    }
  }
  &__rfps-list {
    max-height: 265px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__rfps-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 10px;
    cursor: pointer;
    &__name {
      font-size: 12px;
      flex-grow: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      & > .contact-member-modal__rfps-item__name {
        color: $blue;
      }
    }
  }
}
