.title-icon {
  &_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 0 !important;

    & > span {
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
    }

    & > svg,
    & > img {
      flex: 0 0 auto;
      width: 20px;
      margin-right: 20px;
    }
  }
}

.link {
  color: $link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.video-instruction-link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: none;
  font-weight: normal;
  &.align-right {
    margin-left: auto;
    align-self: flex-end;
  }

  & > svg {
    height: 20px !important;
    width: 20px !important;
    margin-top: 0px !important;
  }
  & > a {
    color: $link;
    text-decoration: none;
    margin-bottom: 0px !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.text-card {
  &__wrapper {
    background-color: rgba(27, 162, 204, 0.05);
    padding: 5px 10px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.__transparent {
      background-color: transparent;
    }

    &.hide-info span {
      font-weight: 700;
      text-transform: uppercase;
    }

    span {
      position: relative;
      top: -2px;
    }
  }
}

.project-label {
  &_wrapper {
    display: flex;
    border: 1px solid $purple;
    border-radius: 3px;
    height: 22px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    user-select: none;
    &.__full {
      .project-label_name,
      .project-label_code {
        max-width: 150px;
      }
      .project-label_name {
        min-width: 25%;
      }
      .project-label_code {
        min-width: fit-content;
      }
    }
    &.__full.__wide-name {
      .project-label_name {
        max-width: unset !important;
        flex-grow: 2;
      }
    }
    &.__large {
      height: 32px;
      .project-label_name,
      .project-label_code {
        padding: 5px;
      }
    }
    &.__huge {
      height: 40px;
      font-size: 14px;
      max-width: 40vw;
      .project-label_name,
      .project-label_code {
        padding: 10px;
      }
    }
    &.__discreteText {
      border-color: $discrete-text !important;
      .project-label_name {
        color: $discrete-text !important;
      }
      .project-label_code {
        background-color: $discrete-text !important;
      }
    }
    &.__green {
      border-color: $green !important;
      .project-label_name {
        color: $green !important;
      }
      .project-label_code {
        background-color: $green !important;
      }
    }
    &.__highlight {
      .project-label_name {
        background-color: $light-yellow;
      }
    }
    &.__clickable {
      cursor: pointer;
    }
    &.__code-rounded {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      .project-label_code {
        border: 0px solid transparent;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &.__medium-text {
      font-size: 12px;
      & > .project-label_code,
      & > .project-label_name {
        padding: 0px 7px;
      }
    }
  }
  &_name,
  &_code {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 3px;
    height: 100%;
    line-height: 20px;
    text-align: center;
    &.__empty {
      min-width: 10px;
    }
  }
  &_name {
    color: $purple;
    border-radius: 3px;
  }
  &_code {
    color: $white;
    background-color: $purple;
  }
}

.explanation {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: $white;
    padding: 20px;
    & > svg {
      flex-shrink: 0;
    }
    &.__center {
      margin: auto;
    }
    &.__thin-vertical-padding {
      padding: 10px 20px;
    }
  }
  &_text {
    &.__center {
      text-align: center;
    }
  }
}

.sub-heading {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  h4 {
    margin: 0px 0px 0px 10px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__new-label {
    margin-left: 10px;
    font-size: 10px;
    font-weight: 700;
    color: $red;
  }
}

.label-data {
  &__wrapper {
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $mid-gray;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__data {
    font-size: 16px;
  }
}
