.btn {
  height: 61px;
  padding: 20px 80px;
  text-align: center;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  line-height: 130%;
  outline: none;
  background-color: $link;
  cursor: pointer;
  color: #fff;
  &[disabled] {
    cursor: not-allowed;
    border: 1px solid transparent;
  }
}

button {
  .preloader {
    height: 100%;
  }
}

.btn-custom {
  &_main {
    position: relative;
    cursor: pointer;
    align-items: center;

    &[disabled] {
      > svg > path,
      > * > svg > path {
        fill: $mid-gray !important;
      }
    }

    &.__hoverable-link:hover {
      color: $white;
      background-color: $link !important;
      border-color: $link;
      svg > path,
      span > svg > path {
        fill: $white;
      }
    }

    &.__full-bg {
      background-color: $link !important;
      color: #fff !important;
      & > span > svg > path[fill="#1ba2cc"] {
        fill: $white !important;
      }
      &[disabled] {
        background-color: $light-gray !important;
        color: $mid-gray !important;
      }
      &.__hoverable-link:hover {
        color: $white;
        background-color: $link !important;
        border-color: $link;
        svg > path,
        span > svg > path {
          fill: $white;
        }
      }
    }

    &.__bold {
      font-weight: 700;
    }

    &.__black {
      color: $black;
      border-color: $black;
      svg > path {
        fill: $black;
      }
      &.__full-bg {
        background-color: $black !important;
        svg > path {
          fill: $white;
        }
        &[disabled] {
          background-color: #d8d8d8 !important;
          color: #a9a9a9 !important;
        }
      }
      &.__hoverable:hover {
        color: $white;
        background-color: $black !important;
        svg > path,
        span > svg > path {
          fill: $white;
        }
      }
    }

    &.__red {
      color: $red;
      border-color: $red;
      svg > path {
        fill: $red;
      }
      &.__full-bg {
        background-color: $red !important;
        svg > path {
          fill: $white;
        }
        &[disabled] {
          background-color: #d8d8d8 !important;
          color: #a9a9a9 !important;
        }
      }
      &.__hoverable:hover {
        color: $white;
        background-color: $red !important;
        svg > path,
        span > svg > path {
          fill: $white;
        }
      }
    }

    &.__purple {
      color: $purple;
      border-color: $purple;
      svg > path {
        fill: $purple;
      }
      &.__full-bg {
        background-color: $purple !important;
        svg > path {
          fill: $white;
        }
        &[disabled] {
          background-color: #d8d8d8 !important;
          color: #a9a9a9 !important;
        }
      }
      &.__hoverable:hover {
        color: $white;
        background-color: $purple !important;
        svg > path,
        span > svg > path {
          fill: $white;
        }
      }
    }

    &.__green {
      color: $green;
      border-color: $green;
      svg > path {
        fill: $green;
      }
      &.__full-bg {
        background-color: $green !important;
        svg > path {
          fill: $white;
        }
        &[disabled] {
          background-color: #d8d8d8 !important;
          color: #a9a9a9 !important;
        }
      }
      &.__hoverable:hover {
        color: $white;
        background-color: $green !important;
        svg > path,
        span > svg > path {
          fill: $white;
        }
      }
    }

    &.__full-width {
      width: 100%;
      & > span {
        width: 100%;
      }
    }

    &.__center-loader {
      justify-content: center;
    }

    &.__large {
      height: 61px !important;
      padding: 20px 80px !important;
    }

    &.__medium {
      height: 36px;
    }

    &.__small {
      height: 32px;
      font-size: 12px;
    }

    &.__tiny {
      height: 22px;
      font-size: 10px;
      font-weight: 700;
      border-radius: 20px;
      padding: 0px 10px;
      & > svg {
        height: 16px;
        width: 16px;
      }
    }

    &.__rounded {
      &.__small {
        border-radius: 16px;
      }
      &.__medium {
        border-radius: 18px;
      }
      &.__large {
        border-radius: 32px;
      }
    }

    &.__circular {
      border-radius: 50%;
      svg {
        margin: 0px;
      }
    }

    &.__no-border {
      border: none;
    }

    &.__show-on-hover {
      border: 1px solid $blue-less-opaque;
      & * {
        visibility: hidden;
      }
      &:hover {
        border: 1px dotted transparent;
        & * {
          visibility: visible;
        }
      }
    }

    &[disabled] {
      cursor: not-allowed;
      border: 1px solid transparent !important;
    }
    &.__image-right {
      img:last-child,
      svg:last-child,
      div.cpointer:last-child > svg {
        margin-right: 0;
        margin-left: 10px;
      }
    }
    &.__non-clickable {
      cursor: default;
    }
    &.__hoverable:hover {
      color: $white;
      background-color: $link !important;
      svg > path,
      span > svg > path {
        fill: $white;
      }
    }
    &.__no-wrap > span {
      white-space: nowrap !important;
    }
  }
  &_showSidebar {
    margin-right: 30px;
    position: relative;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    display: none;
    &:hover {
      span {
        width: 28px;
      }
      &:before,
      &:after {
        width: 28px;
      }
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 26px;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $black;
      transition: 0.2s;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      position: relative;
      display: inline-block;
      width: 22px;
      height: 2px;
      background-color: $black;
      transition: 0.2s;
    }
  }
  &_close-modal {
    display: none;
    position: relative;
    background-color: transparent;
    width: 30px;
    height: 30px;
    padding: 0;
    box-shadow: none;
    border: 0;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 22px;
      height: 2px;
      background-color: $black;
      top: 50%;
      left: 50%;
      transition: 0.4s;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    &._active {
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
        transition-delay: 0.3s;
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
        transition-delay: 0.3s;
      }
    }
  }

  &__delete {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &[disabled] {
      opacity: 0.2;
    }
    &.active-on-hover {
      opacity: 0.2;
      transition: 0.2s;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__edit,
  &__cross,
  &__link {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  &__link {
    padding: 0px;
    padding-top: 2px;
    margin-left: 3px;
    border-bottom: 1px solid $white;
    &:hover {
      color: $link;
      border-bottom: 1px solid $link;
    }
  }
}

.btn-transparent {
  height: 41px;
  background: transparent;
  border: 1px solid $link;
  color: $link;
  border-radius: 3px;
  padding: 10px;
  font-size: 16px;
  line-height: 130%;
  outline: none;
  text-align: center;
  display: flex;
  white-space: nowrap;

  img,
  svg {
    margin-right: 10px;
  }

  & .ant-spin {
    margin: 0 20px;
  }
  &.__danger {
    border-color: #ba2e2e;
    color: #ba2e2e;
  }
  &.__success {
    color: $green;
    border-color: $green;
    svg path {
      fill: $green;
    }
    &-fill {
      color: $white;
      background-color: $green;
      border-color: $green;
      svg path {
        fill: $white;
      }
    }
  }

  &.__icon-button {
    padding: 0px !important;
    border: none !important;
    width: auto;
    height: auto;
    border-color: $light-blue;
    & > svg,
    & > span > svg {
      margin: 5px;
    }
    & > span {
      line-height: 0;
    }
  }

  &.checkbox-initial-button {
    height: 20px;
    width: 20px;
    padding: 0;
    border-radius: 2px;
    & > svg,
    & > span,
    & > span > svg {
      margin: auto;
    }
    & > span.df-row-center {
      height: 18px !important;
    }
  }
}

.remove-btn {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  color: $red;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    color: $black;
  }
}

.make-btn {
  margin-right: 37px;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  .ant-select-selection-item {
    color: $orange;
  }
  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
    background: transparent;
    padding: 0 !important;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      background: url("../../../images/svg/icon-arrow-bottom.svg") no-repeat;
      width: 20px;
      height: 20px;
      background-size: cover;
      margin-right: 7px;
    }
  }
}

.add-btn {
  display: flex;
  border-bottom: 1px dotted $light-gray;
  align-items: center;
  width: 389px;
  padding: 20px 0;
  @include for-size(sm) {
    width: 100%;
  }
  p {
    line-height: 100%;
    margin-bottom: 0;
    margin-left: 16px;
  }
  button {
    background: url("../../../images/svg/add.svg") no-repeat;
    width: 40px;
    height: 40px;
    border: none;
  }
}

.first-login-btn {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  padding: 20px 40px;
  color: $white !important;
  border: 1px solid $link;
  box-sizing: border-box;
  border-radius: 3px;
  background: $link;
  position: absolute;
  top: 28vh;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  color: $black;
  white-space: nowrap;

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    padding: 22px 42px;
  }
}

.handybeaver {
  position: absolute;
  height: 50vh;
  width: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.btn-loader {
  .ant-spin-dot-item {
    background-color: $white;
  }
}

.button-red {
  border-radius: 3px;
  background: indianred;
  color: $white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
}

.btn-bookmark-members {
  display: flex;
  align-items: center;
  &__icon,
  &__filled-icon {
    cursor: pointer;
    &:hover > path {
      fill: $blue;
    }
  }
  &__icon {
    &:hover > rect {
      fill: $blue;
    }
  }
  &__initials {
    margin-right: 2px;
  }
}

.ant-radio-group {
  & > .ant-space.ant-space-horizontal {
    & > .ant-space-item > div > div {
      height: auto;
      font-size: initial;
    }
  }

  .ant-radio-inner {
    border: 1px solid $black !important;
    &::after {
      background-color: $green;
    }
  }
  .ant-radio-wrapper.__mark-unfilled > span.ant-radio > span.ant-radio-inner {
    background-color: $light-yellow;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked.__red-dot {
    .ant-radio.ant-radio-checked > .ant-radio-inner::after {
      background-color: $red;
    }
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-disabled.__colorize-dizabled {
    &.__red-dot-disabled > .ant-radio.ant-radio-checked > .ant-radio-inner::after {
      background-color: $red;
    }
    .ant-radio.ant-radio-checked > .ant-radio-inner::after {
      background-color: $green;
    }
    & > span {
      color: $black;
    }
  }

  &.ant-radio-group-large {
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      .ant-radio {
        top: 0px;
        .ant-radio-input {
          top: 8px;
          left: 8px;
        }
        .ant-radio-inner {
          height: 32px;
          width: 32px;
          &::after {
            width: 16px;
            height: 16px;
            transform: scale(1);
          }
        }
      }
    }
  }
}
