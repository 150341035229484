.load-docs {
  &_wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 40px;
  }
}

.load-document-column {
  &__wrapper {
    margin-bottom: 40px;
    width: 20%;
    @include for-size(sm) {
      width: 100%;
      margin-bottom: 20px;
    }
    flex-grow: 2;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.__not-wide {
      max-width: 250px;
      min-width: 145px;
    }
    &.__half-wide {
      width: 33%;
      flex-grow: 2;
    }
    &.__wide {
      @include for-size(sm) {
        width: 100%;
      }
    }
    &.__full-width {
      width: 100%;
      flex-grow: 2;
    }
    .contract-type {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    padding-left: 0;
    height: 32px;
    margin-bottom: 20px;
    font-size: 14px;
    white-space: nowrap;
    line-height: 1;
    font-weight: 600;
    word-break: break-all;
    text-transform: uppercase;
    svg,
    img {
      flex: 0 0 auto;
      width: 20px;
      margin-right: 10px;
    }
    &.__left {
      margin-left: 0;
    }
    & > button {
      margin-left: auto;
    }
  }
  &__body {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: relative;
    padding: 10px;
    border: 1px dotted #d7d6d6;
    border-radius: 3px;
    min-height: 225px;
    box-sizing: border-box;
    overflow-x: visible;

    &.__edit {
      justify-content: flex-end;
      overflow-x: visible;
      .modal-ent__wrapper.__local {
        transform: translateX(-80%);
      }
    }

    .load-document-column__wrapper {
      width: 100%;
      margin-top: auto;
    }
    .placeholder {
      margin-top: 20px;
      font-size: 12px;
      color: #838383;
      padding: 0 10px 10px 0px;
      display: flex;
    }

    .streaming-external-file {
      &.dalux {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        z-index: 9999;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 20px;
        background: rgba(255, 255, 255, 0.9);
        p {
          text-align: center;
          max-width: 200px;
          &.heading {
            text-transform: uppercase;
          }
        }
        .preloader {
          height: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 100%;
    margin-bottom: auto !important;
    width: 100%;
  }
  &__item {
    display: flex;
    width: 100%;
    font-size: 12px;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 0;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    &.__revision-name {
      margin-top: 10px;
      & + &.__revision-name {
        margin-top: 0px;
      }
      &:first-child {
        margin-top: 0px;
      }
    }

    &:not(.__revision-name):hover {
      z-index: 9999;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
      border-radius: 3px;
      padding: 10px;
      margin: -5px -10px -5px -10px;
      min-width: calc(100% + 20px);
      width: min-content;
      background: white;
      border-color: rgb(240, 240, 240);
      .load-document-column__link {
        overflow: visible;
      }
    }
    svg,
    img {
      flex: 0 0 auto;
      width: 20px;
      margin-right: 5px;
      fill: $link;
    }

    &.__direct-link {
      margin-top: 10px;
      &:hover {
        margin: 5px -10px -5px -10px !important;
      }
      & + &.__direct-link {
        margin-top: 0px;
        &:hover {
          margin: -5px -10px -5px -10px !important;
        }
      }
    }

    &.__no-folder-dalux-file {
      margin-top: 10px;
      &:hover {
        margin: 5px -10px -5px -10px !important;
      }
      & ~ &.__no-folder-dalux-file {
        margin-top: 0px;
        &:hover {
          margin: -5px -10px -5px -10px !important;
        }
      }
    }
  }
  &__link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $link;
    padding: 0;
    flex: 1 1 auto;
    &:hover {
      color: $link;
    }
  }
  &__upload {
    display: block;
    margin-top: 0;
  }
  &__btn-delete {
    flex: 0 0 auto;
    margin-left: 15px;
    padding: 0;
    svg {
      margin-right: 0px;
    }
  }
  &__select {
    border-radius: 3px;
    height: 36px;
    min-width: 125px;
    width: 100%;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
  }
  &__selected {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    width: 170px;
    color: $green;
    svg,
    img {
      margin-right: 10px;
    }
  }
}
